import React, {useState, useEffect} from 'react'
import GraphOutput from '../components/GraphOutput'
import {generateDataSets} from '../thunks/GraphDataGen'
import {AiOutlineUndo} from 'react-icons/ai'

function CalculatorMain() {

  const [form, setForm] = useState({
    'blockName': 'Reference Vineyard',
    'plantingYear': "1990",
    'rootstock': 'Other/Generic',
    'viabilityToggle': false,
    'viabilityThreshold': "0.05",
    'surveyYear': "Select year of survey",
    'surveyedGaps': "15",
    'surveyedVines': "1000",
  })
  const [plantingYears, setPlantingYears] = useState([])
  const [viabilityThresholds, setviabilityThresholds] = useState([])
  const [surveyYears, setSurveyYears] = useState([])
  const [graphData, setGraphData] = useState(generateDataSets(form))
  const [graphHeight, setGraphHeight] = useState(400)
  const [viabilityThreshold, setViabilityThreshold] = useState(true)

  // For setting the range of displayed planting years
  const initRanges = (start, end, increment, kind) => {
    let tempArr = []
    for (let index = start; index <= end; index+=increment) {
      tempArr.push(index)
    }

    switch (kind) {
      case 'plantingYears':
        setPlantingYears(tempArr)
        break;
      case 'viabilityThresholds':
        setviabilityThresholds(tempArr)
        break;
      case 'surveyYears':
        setSurveyYears(tempArr)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    // Run on on component mount
    initRanges(1990,2022,1,"plantingYears")           // integer increases
    initRanges(0.05,0.26,0.01,"viabilityThresholds") // percentage increases
    initRanges(1991,2022,1,"surveyYears")             // integer increases

    setGraphHeight(document.getElementById('graph-id').clientHeight)

  },[])

  useEffect(() => {
    setGraphData(generateDataSets(form))
  }, [form])


  const handleFormUpdate = (e) => {
    if(e.target.type === 'checkbox'){
      setForm({...form, [e.target.name]:e.target.checked})
      setViabilityThreshold(!viabilityThreshold)
    } else {
      setForm((prev) => {
        //  e.target.value === "" || 
        let name = e.target.name
        if((name === 'surveyedVines' || name === 'surveyedVGaps') && parseInt(e.target.value)<=0){ 
          // Do nothing?
          return {...prev, [e.target.name]:1}
        } else {
          return {...prev, [e.target.name]:e.target.value}
        }
      })
    }

    // Updates new planting year ranges
    if(e.target.name === 'plantingYear'){
      initRanges(parseInt(e.target.value)+1,2022,1,"surveyYears")
      if(parseInt(e.target.value) > parseInt(form['surveyYear'])){
        setForm({...form, 'surveyYear':'Select year of survey'})
      }
    }
  }

    // To handle edge case where it's possible for an empty state to be passed to GraphDataGen, resulting in a crash
    const handleOnBlurFormUpdate = (e) => {
      setForm((prev) => {
        if(e.target.value === ""){ 
          
          return {...prev, [e.target.name]: e.target.name === 'surveyedVines' ? 1000 : 15}
        } else {
          return {...prev, [e.target.name]:e.target.value}
        }
      })
    }

  const handleReset = (e) => {
    setForm({...form, 'surveyYear':'Select year of survey'})
  }

  return (
    <div className='calculator-container'>
      <div className='header'>
        <h1 className='heading'>
          Sauvignon Blanc Vine Gap Progression Estimator (Beta)
        </h1>
        <a
        className='feedback-btn' 
        href="mailto:feedback@gaptracker.nz?subject=Gap%20Tracker%20Feedback&body=Let%20us%20know%20your%20suggestion%2C%20or%20in%20the%20case%20of%20a%20bug%20please%20describe%20the%20issue%20you%20encountered%20(with%20screenshots%20where%20relevant).">
           FEEDBACK
        </a>

      </div>

      <section className='flex'>
        <div className='inputs'>
          <h2 className='section-heading'>Report Details</h2>
          <form action="">
            <div className='input-group'>
              <label htmlFor="blockName">Report Name</label>
              <input type="text" name='blockName' placeholder="Block / Planting Name" onBlur={handleFormUpdate} />
            </div>
            <div className='input-group'>
              <label htmlFor="plantingYear">Vine Planting Year</label>
              <select name="plantingYear" id="plantingYear" onChange={handleFormUpdate}>
                {
                  plantingYears.map((item, index) => 
                    (<option value={`${item}`}  key={`plantingYear-${index}`}>{item}</option>)
                  )
                }
              </select>
            </div>
            <div className='input-group'>
              <label htmlFor="rootstock">Rootstock (if known)</label>
              <select name="rootstock" id="rootstock" value={form['rootstock']} onChange={handleFormUpdate}>
                <option value="3309">3309</option>
                <option value="101-14">101-14</option>
                <option value="Schwarzmann">Schwarzmann</option>
                <option value="Riparia Gloire">Riparia Gloire</option>
                <option value="S04/5C">S04/5C</option>
                <option value="Other/Generic">Other/Generic</option> 
              </select>
            </div>
            <div className='input-group'>
              {/* Previously called Viability Threshold */}
              <h3>Productivity Threshold</h3>
              <div className='flex-group flex-gap'>
                <div className='flex-item'>
                  <label className='toggle-wrapper' htmlFor="viabilityToggle" >
                      <input className="toggle-input" type="checkbox" name='viabilityToggle' id='viabilityToggle'  onChange={handleFormUpdate}/>
                      <div className='toggle-fill'></div>
                  </label>
                  
                </div>
                <div className='flex-item'>
                  <label htmlFor="viabilityThreshold"></label>
                  <select name="viabilityThreshold" id="viabilityThreshold"  onChange={handleFormUpdate} disabled={viabilityThreshold}>
                  {
                    viabilityThresholds.map((item, index) => 
                      (<option value={`${item}`}  key={`viabilityThreshold-${index}`}>{Math.trunc(item.toFixed(2)*100)}%</option>)
                    )
                  }
                  </select>
                </div>
              </div>
            </div>
            <h2 className='section-heading'>Vine Survey</h2>
            <div className='input-group'>
              <label htmlFor="surveyYear">Year of vine survey</label> 
              <div className='flex-group'>
                <select name="surveyYear" id="surveyYear" value={form['surveyYear']}  onChange={handleFormUpdate}>
                  <option value="Please Select" key={`surveyYear-select`}>Select year of survey</option>
                {
                  surveyYears.map((item, index) => 
                    (<option value={`${item}`}  key={`surveyYear-${index}`}>{item}</option>)
                  )
                }
                
                </select> 
                <AiOutlineUndo size={30} className='survey-reset' onClick={handleReset}/>
              </div>

            </div>       
            <div className='flex-group flex-gap input-group'>
              <div className='flex-item'>
                <label htmlFor="surveyedVines">Vines Surveyed</label>
                <input type="number" name="surveyedVines"  min="1000" value={form['surveyedVines']} onChange={handleFormUpdate} onBlur={handleOnBlurFormUpdate}/>
              </div>
              <div className='flex-item'>
                <label htmlFor="surveyedGaps">Number of Gaps</label>
                <input type="number" name="surveyedGaps"  min="0" value={form['surveyedGaps']} onChange={handleFormUpdate} onBlur={handleOnBlurFormUpdate}/>
              </div>
            </div>     
            <p className='survey-info'> At least 1000 surveyed vines required for minimum accuracy level</p>  
          </form>
        </div>
        <div className='graph' id='graph-id'>
          <GraphOutput
            formData={form}
            graphData={graphData}
            graphHeight={graphHeight}
          />
        </div>
      </section>
    </div>
  )
}

export default CalculatorMain