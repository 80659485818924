// Data and models courtesy of Beatrix Jones Associate Professor
// Faculty of Science, Statistics, New Zealand
// https://profiles.auckland.ac.nz/beatrix-jones

import {getLastArrayElement} from '../utils/arrayElements'

const rootstockIntercepts = {
  "101-14": 0.204171,
  "3309": 0.204171 - 0.048544,
  "Riparia Gloire": 0.204171 - 0.034189,
  "Schwarzmann": 0.204171 - 0.049795,
  "S04/5C": 0.204171 - 0.027356,
  "Other/Generic": 0.170139,
};


// Broadly trying to keep variable names aligned with Beatrix's work for ease of cross referencing
const generateDataSets = (form) => {
  let {
    plantingYear,
    viabilityThreshold,
    rootstock,
    surveyYear,
    surveyedGaps,
    surveyedVines,
  } = form;

  plantingYear = parseInt(plantingYear)
  surveyedGaps = parseInt(surveyedGaps = surveyedGaps <= 0 ? 1 : surveyedGaps)
  viabilityThreshold = parseFloat(viabilityThreshold) * 100
  surveyYear = surveyYear !== 'Select year of survey' ? parseInt(surveyYear) : surveyYear

  // Model inputs
  const intercept = rootstockIntercepts[rootstock]
  const vineyardSD = 0.08281
  const residualSD = 0.02304
  const yearCoefficient = 0.011009;

  // Placeholders for dataset generation
  const areaRangeReference = []
  const areaRangeReferenceErrorRangeOuterBounds = []
  const referenceUpperBoundLine = []
  const referenceLowerBoundLine = []
  const areaRangeReferenceErrorRangeInnerLower = []
  const areaRangeReferenceErrorRangeInnerUpper = []

  const areaRangeProjection = []
  const areaRangeProjectionErrorRange = []
  // Viability intercept lines
  let viabilityConditions
  let viabilityYear = null
  let exitYear = null

  // Flow control
  let currentYear = 0
  const endYearTarget = 11
  let upperErrorThreshold = 0.28
  let continueFlag = true

  // To assess flow control at end of loop
  do{

    // Calculate the reference vineyard's 50th percentile + upper & lower confidence interval bands
    let {reference, lowerBound, upperBound, innerLowerBound, innerUpperBound} = calculateReferenceDataSet(intercept, yearCoefficient, currentYear, vineyardSD, 2.576, 1.645)
    
    areaRangeReference.push([plantingYear+currentYear,(reference**4)*100])
    areaRangeReferenceErrorRangeOuterBounds.push([plantingYear+currentYear,(lowerBound**4)*100,(upperBound**4)*100]) 
    referenceLowerBoundLine.push([plantingYear+currentYear, (lowerBound**4)*100])
    referenceUpperBoundLine.push([plantingYear+currentYear, (upperBound**4)*100])
    areaRangeReferenceErrorRangeInnerLower.push([plantingYear+currentYear,(innerLowerBound**4)*100]) 
    areaRangeReferenceErrorRangeInnerUpper.push([plantingYear+currentYear,(innerUpperBound**4)*100]) 

    // Calculate surveyed sample set, if a missing vine sample has been carried out
    // Using currentYear+plantingYear to allow for direct comparison to actual Gregorian calendar year
    if(surveyYear !== "Select year of survey" && currentYear+plantingYear >= surveyYear){
      // Calculate the target surveyed vineyard's 50th percentile + upper & lower confidence interval bands
      let {trajectory, trajectoryLowerOuterBound, trajectoryUpperOuterBound} = calculateSurveyedVineyardDataset(surveyedGaps, surveyedVines, intercept, yearCoefficient, surveyYear, plantingYear, currentYear, residualSD, 2.576, 1.645)

      areaRangeProjection.push([plantingYear+currentYear, (trajectory**4)*100])
      areaRangeProjectionErrorRange.push([plantingYear+currentYear, (trajectoryLowerOuterBound**4)*100, (trajectoryUpperOuterBound**4)*100])
    }
    
    viabilityConditions = calculateViabilityThresholds(
      currentYear, 
      plantingYear, 
      // areaRangeProjection.length === 0 ? areaRangeReference.at(-1)[1] : areaRangeProjection.at(-1)[1], // currentMissingPct,
      // If no survey has been undertaken, use the reference vineyard data set. If a survey has, check to see if any data has been generated for it. If not, use null.
      surveyYear === "Select year of survey" ? getLastArrayElement(areaRangeReference)[1] : areaRangeProjection.length === 0 ? null : getLastArrayElement(areaRangeProjection)[1], // currentMissingPct,
      viabilityYear, 
      viabilityThreshold, 
      endYearTarget, 
      exitYear)
    
    viabilityYear = viabilityConditions.viabilityYear
    exitYear = viabilityConditions.exitYear

    currentYear += 1

    // Exit conditions
    if((upperBound**4 >= upperErrorThreshold) && (currentYear >= endYearTarget)&& (currentYear+plantingYear >= exitYear) && (exitYear !== null)){
      // console.log(viabilityYear, endYearTarget, currentYear, plantingYear, viabilityYear+endYearTarget, ">=", currentYear+plantingYear, viabilityYear+endYearTarget >= currentYear+plantingYear);
      if(typeof(surveyYear) === 'string'){
        // If above conditions met but survey hasn't been carried out, then exit
        continueFlag = false
      } else if(typeof(surveyYear) === 'number' && (currentYear+plantingYear) >= (surveyYear+endYearTarget)){
        //if survey has been carried out (type: number) and the current date year is at least 10 years greater than the survey date, exit
        continueFlag = false
      }
      // else continueFlage remains true
      
      
    }
  } while(continueFlag)

  return {areaRangeReference, areaRangeReferenceErrorRangeOuterBounds, referenceLowerBoundLine, referenceUpperBoundLine, areaRangeReferenceErrorRangeInnerLower, areaRangeReferenceErrorRangeInnerUpper, areaRangeProjection, areaRangeProjectionErrorRange, viabilityYear}
};

const calculateReferenceDataSet = (intercept, yearCoefficient, currentYear, vineyardSD, zValue1, zValue2) => {

  let reference = intercept + yearCoefficient * currentYear
  reference = reference > 1 ? 1 : reference // Limit to 100%
  let lowerBound = calculateReferenceBounds(reference, zValue1, vineyardSD, 'lower')
  let upperBound = calculateReferenceBounds(reference, zValue1, vineyardSD, 'upper')
  let innerLowerBound = calculateReferenceBounds(reference, zValue2, vineyardSD, 'lower')
  let innerUpperBound = calculateReferenceBounds(reference, zValue2, vineyardSD, 'upper')

  return {reference, lowerBound, upperBound, innerLowerBound, innerUpperBound}
}

const calculateSurveyedVineyardDataset = (surveyedGaps, surveyedVines, intercept, yearCoefficient, surveyYear, plantingYear, currentYear, residualSD, zValue1, zValue2) => {
  let obsProp = surveyedGaps/surveyedVines // Observed Proportion of Missing Vines percentage of vines missing from last survey
  // VineyardRes @ time of survey was undertaken, offset from plantingYear (e.g. 2014 - 2004 = 10)
  let vineyardRes = (obsProp**0.25)-(intercept + yearCoefficient * (surveyYear-plantingYear))
  let trajectory = intercept + yearCoefficient * currentYear + vineyardRes
  trajectory = trajectory > 1 ? 1 : trajectory // Limit to 100%
  let trajectoryLowerOuterBound = calculateReferenceBounds(trajectory, zValue1, residualSD, 'lower')
  let trajectoryUpperOuterBound = calculateReferenceBounds(trajectory, zValue1, residualSD, 'upper')

  return {trajectory, trajectoryLowerOuterBound, trajectoryUpperOuterBound}
}

const calculateReferenceBounds = (reference, zValue, stdev, boundType) => {
  // Using the bound limit to determine whether the operation should (+) or (-)
  let bound = boundType === 'upper' ? (reference + (zValue * stdev)) : (reference - (zValue * stdev))
  // Putting limits in place. Can never go below zero, can't go above 1 (100%)
  bound = bound >= 1 ? 1 : bound
  bound = bound <= 0 ? 0 : bound   
  return bound
}

const calculateViabilityThresholds = (currentYear, plantingYear, currentMissingPct, viabilityYear, viabilityThreshold, minYearsToDisplay, exitYear) => {
  if(viabilityYear === null && currentMissingPct !== null){
    //do math to check viability year
    viabilityYear = currentMissingPct >= viabilityThreshold ? currentYear+plantingYear : viabilityYear
    exitYear = viabilityYear !== null ? currentYear + plantingYear + minYearsToDisplay : null
    // exitYear = currentMissingPct >= viabilityThreshold ? currentYear + minYearsToDisplay  : 0
  } 
  return {viabilityYear, exitYear}
}

// const getLastArrayElement = (arr, position) => {
//   // Older browsers don't support Prototype.Array.at(), so replacing it with custom function
//   return arr[arr.length - 1]
// }

export { generateDataSets };
