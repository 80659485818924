import CalculatorMain from "./pages/CalculatorMain";

function App() {
  return (
    <>
      <CalculatorMain></CalculatorMain>
    </>
  );
}

export default App;
