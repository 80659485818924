import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more'
import HC_annotations from 'highcharts/modules/annotations'
import HC_Exporting from 'highcharts/modules/exporting'
import HC_ExportingOffline from 'highcharts/modules/offline-exporting'

import {getLastArrayElement} from '../utils/arrayElements'

function GraphOutput({formData, graphData, graphHeight}) {

  HC_more(Highcharts)
  HC_annotations(Highcharts)
  HC_Exporting(Highcharts);
  HC_ExportingOffline(Highcharts);
  // Destructure incoming props
  let {
    blockName,
    // plantingYear,
    // viabilityThreshold,
    viabilityToggle,
    rootstock,
    // surveyYear,
    // surveyedGaps,
    // surveyedVines,
  } = formData;

  let {
    areaRangeReference, 
    referenceLowerBoundLine, 
    referenceUpperBoundLine,
    areaRangeReferenceErrorRangeOuterBounds, 
    areaRangeReferenceErrorRangeInnerLower,
    areaRangeReferenceErrorRangeInnerUpper,
    areaRangeProjection,
    areaRangeProjectionErrorRange, 
    viabilityYear
  } = graphData

  const options = {
    credits: {
      enabled: false
    },
    chart: {
      height: `${graphHeight}px`,
    },
    tooltip: {
      valueDecimals: 2
    },
    title: {
      text: `${blockName} (${rootstock})`
    },
    yAxis: [
      {
        title: {
            text: 'Percentage of Vines Missing',
            style: {
              fontSize: '16px',
            },
            margin: 18
        },
        tickInterval: 1,
        maxPadding: 0,
      }, 
      {
        title: {
          text: 'Percentile',
          style: {
            fontSize: '16px',
          },
          rotation: 270,
          margin: 60
        },
        opposite: true
      }
    ],
    xAxis: {
      tickInterval: 1,
      plotLines: [{
        color: "red",
        width: 2,
        value: viabilityToggle === false ? 0 : viabilityYear,
        dashStyle: 'ShortDash'
      }],
      plotBands: {
        from: viabilityToggle === false ? 0 : viabilityYear,
        to: viabilityToggle === false ? 0 : getLastArrayElement(areaRangeReference)[0],
        color: '#fcf1f1'
      }   
    },
    plotOptions: {
      series: {
          label: {
              connectorAllowed: false
            },
          marker: false,
          states: {
            inactive: {
              opacity: 1
            }
          },
          enableMouseTracking: true
        },
      arearange:{
      }
    },
    exporting:{
      sourceWidth: 1280
    },
    // Annotations for Confidence Intervals / Percentiles
    annotations:[{
      crop: false,
      draggble: '',
      labelOptions:{
        overflow: 'allow',
        shape: 'connector',
        align: 'left',
        x: -1,
        y: 18,
        padding: 18

      },
      labels:[
        {
          point:{
            x: getLastArrayElement(referenceUpperBoundLine)[0],
            y: getLastArrayElement(referenceUpperBoundLine)[1],
            xAxis: 0,
            yAxis: 0
          },
          text: "99th",
          // overflow: 'allow'
        },
        {
          point:{
            x: getLastArrayElement(areaRangeReferenceErrorRangeInnerUpper)[0],
            y: getLastArrayElement(areaRangeReferenceErrorRangeInnerUpper)[1],
            xAxis: 0,
            yAxis: 0
          },
          text: "90th",
          // overflow: 'allow'
        },
        {
          point:{
            x: getLastArrayElement(areaRangeReference)[0],
            y: getLastArrayElement(areaRangeReference)[1],
            xAxis: 0,
            yAxis: 0
          },
          text: "50th"
        },        
        {
          point:{
            x: getLastArrayElement(areaRangeReferenceErrorRangeInnerLower)[0],
            y: getLastArrayElement(areaRangeReferenceErrorRangeInnerLower)[1],
            xAxis: 0,
            yAxis: 0
          },
          text: "10th"
        },
        {
          point:{
            x: getLastArrayElement(referenceLowerBoundLine)[0],
            y: getLastArrayElement(referenceLowerBoundLine)[1],
            xAxis: 0,
            yAxis: 0
          },
          text: "1st"
        },
      ]
    }],

    series: [
      {
        name: 'Reference Vineyard - 50th',
        data: areaRangeReference,
        zIndex: 1,
        showInLegend: false,
        color: '#848984',
      },
      {
        name: 'Error Range Reference',
        data: areaRangeReferenceErrorRangeOuterBounds,
        type: 'arearange',
        lineWidth: 1,
        linkedTo: ':previous',
        color: '#edeaea', //was #bcbcbc
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {            
          enabled: true
        },
        trackByArea: false     
      },
      {
        name: 'Reference Vineyard 1st Percentile',
        data: referenceLowerBoundLine,
        zIndex: 1,
        showInLegend: false,
        color: '#',
      },
      {
        name: 'Reference Vineyard 99th Percentile',
        data: referenceUpperBoundLine,
        zIndex: 1,
        showInLegend: false,
        color: '#',
      },
      {
        name: 'Reference Vineyard 10th Percentile',
        data: areaRangeReferenceErrorRangeInnerLower,
        zIndex: 1,
        showInLegend: false,
        lineWidth: 1,
        color: '#c1c5c1',
      },
      {
        name: 'Reference Vineyard 90th Percentile',
        data: areaRangeReferenceErrorRangeInnerUpper,
        zIndex: 1,
        showInLegend: false,
        lineWidth: 1,
        color: '#c1c5c1',
      },
      { //
        name: 'Survey Projection 50th Percentile',
        data: areaRangeProjection,
        zIndex: 1,
        showInLegend: false,
        color: "#3591d1",
      },
      {
        name: 'Survey Projection Error',
        data: areaRangeProjectionErrorRange,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: Highcharts.getOptions().colors[0],
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {            
          enabled: false
        },
        trackByArea: false
      },
      // {
      //   name: 'Vertical Viability Threshold',
      //   data: viabilityToggle === false ? 0 : viabilityYear,
      //   zIndex: 1,
      //   showInLegend: false,
      //   color: '#ae732c9e',
      //   lineWidth: 3,
      //   dashStyle: 'ShortDash',
      // },
      {
        name: 'Vertical Viability Threshold',
        type: 'area',
        zIndex: 1,
        showInLegend: false,
        color: '#d1a1a19e',
        lineWidth: 0,
        getExtremesFromAll: true
      },  
    ]
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  )
}

export default GraphOutput